<template>
  <div class="info">
    <div class="info__id">
      <strong>{{ item.documentId }}</strong>
    </div>
    <div v-if="item.postalCode && item.city" class="info__item">
      <strong>{{ $t("targetPostalCode") }}</strong>
      <span>{{ item.postalCode }}, {{ item.city.toUpperCase() }}</span>
    </div>
    <div v-if="item.weight" class="info__item">
      <strong>{{ $t("weight") }}</strong>
      <span>{{ parseWeight(item.weight) }}</span>
    </div>
    <div v-if="item.deliveryDate" class="info__item">
      <strong>{{ $t("deliveryDate") }}</strong>
      <span>{{ new Date(item.deliveryDate).toLocaleDateString("fi-FI") }}</span>
    </div>
    <div v-if="item.externalCode" class="info__item">
      <strong>{{ $t("externalCode") }}</strong>
      <span>{{ item.externalCode }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  props: ["item"],
  methods: {
    parseWeight: function (weight) {
      return weight > 1000
        ? `${parseFloat(weight / 1000).toFixed(2)} kg`
        : `${weight} g`;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "src/styles/variables"

.info
  padding: 16px
  margin: 32px 0
  background: rgba(0, 0, 0, 0.02)
  border-radius: 5px

  &__id
    padding-bottom: 14px

  &__item
    padding: 4px 0
    display: flex

    strong
      flex: 1

    span
      padding-left: 2rem
      flex: 1
      word-break: break-word

    @media (max-width: $mobileMaxWidth)
      flex-direction: column
      padding: 6px 0

      span
        padding-left: 0
</style>
