export default {
  "trackingUrlPath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uppfoljning"])},
  "itemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försändelsekod"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
  "trackTheParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spåra paket"])},
  "noResultsFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Försändelsen med koden <strong>", _interpolate(_named("id")), "</strong> hittades inte"])},
  "multipleItemsFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " paket"])},
  "trackingStages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försändelsens status"])},
  "targetPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Försändelsens avsändningsadress"])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vikt"])},
  "trackingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spåra din försändelse"])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka"])},
  "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppföljning - Jakeluyhtiö Suomi"])},
  "deliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beräknat leveransdatum"])},
  "externalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern försändelsekod"])}
}