export default {
  "trackingUrlPath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seuranta"])},
  "itemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetystunnus"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae"])},
  "trackTheParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraa pakettia"])},
  "noResultsFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lähetystunnuksella <strong>", _interpolate(_named("id")), "</strong> ei löytynyt yhtään pakettia."])},
  "multipleItemsFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " Pakettia"])},
  "trackingStages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetyksen vaiheet"])},
  "targetPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohdepostinumero"])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paino"])},
  "trackingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähetyksen seuranta"])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palaa takaisin"])},
  "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuranta - Jakeluyhtiö Suomi"])},
  "deliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvioitu toimituspäivä"])},
  "externalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulkoinen seurantatunnus"])}
}