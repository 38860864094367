export default {
  "trackingUrlPath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tracking"])},
  "itemId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item ID"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "trackTheParcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track the parcel"])},
  "noResultsFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Didn't find any packages for Item ID <strong>", _interpolate(_named("id")), "</strong>"])},
  "multipleItemsFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " Packages"])},
  "trackingStages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking stages"])},
  "targetPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target postal code"])},
  "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
  "trackingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item tracking"])},
  "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
  "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking - Jakeluyhtiö Suomi"])},
  "deliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated delivery date"])},
  "externalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External tracking code"])}
}